import { Li, Span, Text, Ul, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import pluralize from "pluralize";

interface ShiftInviteMissingFacilityRequiredDocumentsDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  missingDocuments: string[];
}

export function ShiftInviteMissingFacilityRequiredDocumentsDialog(
  props: Readonly<ShiftInviteMissingFacilityRequiredDocumentsDialogProps>
) {
  const { modalState, onConfirm, missingDocuments } = props;
  const pluralizedDocument = pluralize("document", missingDocuments.length);

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <>
          You&apos;re missing{" "}
          <Span color={(theme) => theme.palette.primary.main}>{missingDocuments.length}</Span>{" "}
          {pluralizedDocument}
        </>
      }
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Accept Invite
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={2}>
        <Text paragraph>
          You are missing the following {pluralizedDocument} required by the workplace:
        </Text>
        <Ul>
          {missingDocuments.slice(0, 6).map((document) => (
            <Text key={document} bold variant="body2">
              <Li>{document}</Li>
            </Text>
          ))}
        </Ul>
        <Text paragraph>
          You can still accept this shift invite, but please make sure to upload the above{" "}
          {pluralizedDocument} as soon as you can.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
