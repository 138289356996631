import { BaseLink, Image, Text } from "@clipboard-health/ui-react";
import { Box, Button, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { SHIFT_DISCOVERY_LIST_PATH } from "@src/appV2/ShiftDiscovery/paths";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/ShiftDiscovery/utils/useIsShiftDiscoveryEnabled";

export function EmptyShiftInvites() {
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Image width="160px" src="assets/logo/bunny.png" alt="Clipboard logo" />
      <Box
        paddingX={{
          xs: 2,
          sm: 4,
        }}
      >
        <Text variant="subtitle2" align="center">
          You don’t have any shift invites right now. Invites are sent from facilities directly to
          workers.
        </Text>
        <Button
          fullWidth
          component={BaseLink}
          variant="contained"
          color="primary"
          to={
            isShiftDiscoveryEnabled
              ? `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`
              : DeprecatedGlobalAppV1Paths.OPEN_SHIFTS
          }
        >
          Find Shifts
        </Button>
      </Box>
    </Stack>
  );
}
