import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

import { type Shift } from "../../Shift/types";

interface ShiftInviteConflictDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  conflictingShift: Shift;
}

export function ShiftInviteConflictDialog(props: ShiftInviteConflictDialogProps) {
  const { modalState, onConfirm, conflictingShift } = props;
  const conflictedFacilityName = conflictingShift.facility.name;

  return (
    <CommonDialog
      modalState={modalState}
      title="You're booked for a conflicting shift"
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            View My Shifts
          </Button>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text paragraph>
          The shift you are invited to overlaps with your booked shift at {conflictedFacilityName}.
          You can cancel your other booked shift to accept this invite (Attendance Score penalties
          may apply).
        </Text>
      </Stack>
    </CommonDialog>
  );
}
