import { Span, Text, type UseModalState } from "@clipboard-health/ui-react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Button, List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { uniq } from "lodash";
import pluralize from "pluralize";

import { type ShiftInvite } from "../types";

interface ShiftInviteConflictingInviteDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  onCancel: () => any;
  conflictingInvites: ShiftInvite[];
}

export function ShiftInviteConflictingInviteDialog(props: ShiftInviteConflictingInviteDialogProps) {
  const { modalState, onConfirm, onCancel, conflictingInvites } = props;
  const pluralizedInvite = pluralize("invite", conflictingInvites.length);

  const facilityNames = uniq(conflictingInvites.map((invite) => invite.attributes.facility.name));

  const facilityNameText =
    facilityNames.length <= 1
      ? facilityNames[0]
      : `${facilityNames.slice(0, -1).join(", ")} and ${facilityNames.at(-1)!}`;

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <>
          You have{" "}
          <Span color={(theme) => theme.palette.primary.main}>{conflictingInvites.length}</Span>{" "}
          conflicting shift {pluralizedInvite}
        </>
      }
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Accept Anyway
          </Button>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Back to Invites
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text paragraph>
          The shift you are invited to overlaps with your other {pluralizedInvite} at{" "}
          {facilityNameText}. By accepting this shift invite, your{" "}
          {conflictingInvites.length === 1 ? "" : conflictingInvites.length} conflicting shift{" "}
          {pluralizedInvite} will be automatically declined.
        </Text>
        <List>
          {conflictingInvites.map((invite) => (
            <ListItem key={invite.id}>
              <ListItemIcon>
                <ApartmentIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                <Text variant="body2">
                  {invite.attributes.facility.name} (
                  {formatDollarsAsUsd(invite.attributes.shiftDetails.pay)} per hour)
                </Text>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Stack>
    </CommonDialog>
  );
}
