import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface ShiftInviteWeeklyHoursLimitExceededDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
  weeklyHoursLimit: number;
}

export function ShiftInviteWeeklyHoursLimitExceededDialog(
  props: ShiftInviteWeeklyHoursLimitExceededDialogProps
) {
  const { modalState, onConfirm, weeklyHoursLimit } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="You have reached the weekly hours limit at this workplace"
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            View My Shifts
          </Button>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text paragraph>
          This workplace does not allow professionals to book more than {weeklyHoursLimit} hours of
          work per week. You can cancel your other booked shifts at this workplace to accept this
          invite (Attendance Score penalties may apply).
        </Text>
      </Stack>
    </CommonDialog>
  );
}
